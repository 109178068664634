import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';

import Sidebar from "../components/sidebar";
import Topbar from "../components/topbar";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch('https://fullstack-backend.info-286.workers.dev/api/posts');
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (    
    <div>
      <div id="wrapper">
          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <Topbar />

                  <div className="container-fluid">
                      <div className="d-sm-flex align-items-center justify-content-between mb-4">
                          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                          <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                  className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                      </div>

                      <div className="row">
                          <div className="col-xl-12 col-lg-12">
                              <div className="card shadow mb-4">
                                  <div
                                      className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                      <h6 className="m-0 font-weight-bold text-primary">Table</h6>
                                      <div className="dropdown no-arrow">
                                          <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                              aria-labelledby="dropdownMenuLink">
                                              <div className="dropdown-header">Dropdown Header:</div>
                                              <a className="dropdown-item" href="#">Action</a>
                                              <a className="dropdown-item" href="#">Another action</a>
                                              <div className="dropdown-divider"></div>
                                              <a className="dropdown-item" href="#">Something else here</a>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="card-body">
                                    <div className="table-responsive">
                                      <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                          <thead>
                                              <tr>
                                                  <th>Name</th>
                                              </tr>
                                          </thead>
                                          <tfoot>
                                              <tr>
                                                  <th>Name</th>
                                              </tr>
                                          </tfoot>
                                          <tbody>
                                            {posts.map(post => (
                                              <tr key={post.id}>
                                                <td>
                                                  <Link to={`/posts/${post.id}`}>{post.title}</Link>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                      </table>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-lg-6 mb-4">
                              <div className="card shadow mb-4">
                                  <div className="card-header py-3">
                                      <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
                                  </div>
                                  <div className="card-body">
                                      <h4 className="small font-weight-bold">Server Migration <span
                                              className="float-right">20%</span></h4>
                                      <div className="progress mb-4">
                                          <div className="progress-bar bg-danger" role="progressbar" style={{width: "20%"}}
                                              aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                      <h4 className="small font-weight-bold">Sales Tracking <span
                                              className="float-right">40%</span></h4>
                                      <div className="progress mb-4">
                                          <div className="progress-bar bg-warning" role="progressbar" style={{width: "40%"}}
                                              aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                      <h4 className="small font-weight-bold">Customer Database <span
                                              className="float-right">60%</span></h4>
                                      <div className="progress mb-4">
                                          <div className="progress-bar" role="progressbar" style={{width: "60%"}}
                                              aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                      <h4 className="small font-weight-bold">Payout Details <span
                                              className="float-right">80%</span></h4>
                                      <div className="progress mb-4">
                                          <div className="progress-bar bg-info" role="progressbar" style={{width: "80%"}}
                                              aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                      <h4 className="small font-weight-bold">Account Setup <span
                                              className="float-right">Complete!</span></h4>
                                      <div className="progress">
                                          <div className="progress-bar bg-success" role="progressbar" style={{width: "100%"}}
                                              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-primary text-white shadow">
                                          <div className="card-body">
                                              Primary
                                              <div className="text-white-50 small">#4e73df</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-success text-white shadow">
                                          <div className="card-body">
                                              Success
                                              <div className="text-white-50 small">#1cc88a</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-info text-white shadow">
                                          <div className="card-body">
                                              Info
                                              <div className="text-white-50 small">#36b9cc</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-warning text-white shadow">
                                          <div className="card-body">
                                              Warning
                                              <div className="text-white-50 small">#f6c23e</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-danger text-white shadow">
                                          <div className="card-body">
                                              Danger
                                              <div className="text-white-50 small">#e74a3b</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-secondary text-white shadow">
                                          <div className="card-body">
                                              Secondary
                                              <div className="text-white-50 small">#858796</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-light text-black shadow">
                                          <div className="card-body">
                                              Light
                                              <div className="text-black-50 small">#f8f9fc</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 mb-4">
                                      <div className="card bg-dark text-white shadow">
                                          <div className="card-body">
                                              Dark
                                              <div className="text-white-50 small">#5a5c69</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>

                          <div className="col-lg-6 mb-4">
                              <div className="card shadow mb-4">
                                  <div className="card-header py-3">
                                      <h6 className="m-0 font-weight-bold text-primary">Illustrations</h6>
                                  </div>
                                  <div className="card-body">
                                      <div className="text-center">
                                          <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}}
                                              src="img/undraw_posting_photo.svg" alt="..." />
                                      </div>
                                      <p>Add some quality, svg illustrations to your project courtesy of <a
                                              target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a
                                          constantly updated collection of beautiful svg images that you can use
                                          completely free and without attribution!</p>
                                      <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on
                                          unDraw &rarr;</a>
                                  </div>
                              </div>

                              <div className="card shadow mb-4">
                                  <div className="card-header py-3">
                                      <h6 className="m-0 font-weight-bold text-primary">Development Approach</h6>
                                  </div>
                                  <div className="card-body">
                                      <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classNamees in order to reduce
                                          CSS bloat and poor page performance. Custom CSS classNamees are used to create
                                          custom components and custom utility classNamees.</p>
                                      <p className="mb-0">Before working with this theme, you should become familiar with the
                                          Bootstrap framework, especially the utility classNamees.</p>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>

              <footer className="sticky-footer bg-white">
                  <div className="container my-auto">
                      <div className="copyright text-center my-auto">
                          <span>Copyright &copy; Your Website 2021</span>
                      </div>
                  </div>
              </footer>
          </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
};

export default Posts;