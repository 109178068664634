import { Router } from '@reach/router';

import Posts from './pages/posts';
import Post from './pages/post';

function App() {
  return (
    <div className="App">
      <Router>
        <Posts path="/" />
        <Post path="/posts/:id" />
      </Router>
    </div>
  );
}

export default App;